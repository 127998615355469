<template>
  <section class="cabin-checklists">
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="12">
        <div v-if="checklists.length" class="cabin-checklists_list">
          <CheckList
            v-for="(item, index) in checklists"
            :editable="false"
            :checklist="item"
            :key="index"
          ></CheckList>
          <router-link v-if="(isCabinAdministrator() || isCabinOwner())" class="editChecklists" :to="{ name: 'editChecklists' }">
            <Edit /> <span>Rediger dine sjekklister</span>
          </router-link>
        </div>
        <div v-else class="cabin-checklists_none">
          <div class="cabin-checklists_none--inner">
            <img alt="Sjekklister" src="../../../assets/planning.png" />
            <h1>Hvordan</h1>
            <p>Kontroller bruken- og ta vare på hytta ved å lage sjekklister som brukerne skal følge.</p>
            <Button v-if="(isCabinAdministrator() || isCabinOwner())" @click="toggleAddChecklist()" color="lightgreen" text="Opprett ny liste" size="large" symbol="plus"></Button>
          </div>
        </div>
      </column>
      <column :xs="12" :md="12" :lg="12">
        <ChecklistHistory 
          v-if="(isCabinAdministrator() || isCabinOwner()) && checklistHistory.length > 0" 
          :checklistHistoryItems="checklistHistory">
        </ChecklistHistory>
      </column>
    </row>
  </section>
</template>
<script>
import CheckList from '@/components/CheckList';
import ChecklistHistory from '@/components/checklists/ChecklistHistory';
import ChecklistService from '@/services/ChecklistService';
import Edit from '@/assets/edit.svg';
import Button from '@/components/Button';

export default {
  name: 'checklists',
  components: {
    CheckList,
    ChecklistHistory,
    Edit,
    Button
  },
  props: {
    cabinUserRoleId: Number,
    cabin: Object
  },
  data() {
    return {
      checklists: []
    }
  },
  computed: {
    checklistHistory() {
      let checklistHistoryItems = [];
      let userIds = [];
      this.checklists.forEach((checklist) => {
        checklist.checklistHistory.forEach((history) => {
          userIds.push(history.userId);
          history.name = checklist.name;
          checklistHistoryItems.push(history);
        })
      });

      let sortedChecklistHistory = checklistHistoryItems.sort((a, b) => {
        if(a.entryTimeUtc > b.entryTimeUtc) {
          return -1;
        }
        return 1;
      }).slice(0, 5);

      return sortedChecklistHistory;
    }
  },
  methods: {
    isCabinOwner() {
      if (this.cabinUserRoleId === 1) return true;

      return false;
    },
    isCabinAdministrator() {
      if (this.cabinUserRoleId === 2) return true;

      return false;
    },
    isCabinUser() {
      if (this.cabinUserRoleId === 3) return true;

      return false;
    },
    toggleAddChecklist() {
      this.$router.push({ name: 'addChecklist'});
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await ChecklistService.getChecklists(to.params.cabinId);
    next((vm) => {
      if (data) {
        vm.checklists = data;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
  .cabin-checklists {
    @include gutter-padding;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 6rem;
    }
    @media (min-width: $breakpoint-mobile) {
      margin: 4rem 0;
    }
    &_none {
      @media (min-width: $breakpoint-mobile) {
        width: 40%;
      }
      margin: 0 auto;
      &--inner {
        background-color: white;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
        img {
          width: 40%;
          margin: 0 auto;
          margin-bottom: 2rem;
        }
        p {
          text-align: center;
        }
      }
    }
    .editChecklists {
      display: flex;
      justify-content: center;
      align-items: center;
      color:black;
      font-weight: 500;
      margin: 2rem 0 3rem 0;
      span {
        margin-left: .5rem;
      }
    }
  }
</style>