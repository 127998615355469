<template>
  <div class="checklist-history">
    <h2>Sist gjennomført:</h2>
    <ChecklistHistoryItem
      v-for="(item, index) in checklistHistoryItems"
      :key="index"
      :checklistHistoryItem="item">
    </ChecklistHistoryItem>
  </div>
</template>
<script>
import ChecklistHistoryItem from './ChecklistHistoryItem';

export default {
  name: 'ChecklistHistory',
  props: {
    checklistHistoryItems: Array
  },
  components: {
    ChecklistHistoryItem
  },
}
</script>
<style lang="scss" scoped>
  h2 {
    font-weight: 500;
  }
</style>
