<template>
  <div class="checklist-history-item">
    <h3>{{ checklistHistoryItem.name }}</h3>
    <span class="checklist-history-item_user"></span>
    <span class="checklist-history-item_date">{{ formatDate(checklistHistoryItem.entryTimeUtc) }}</span>
  </div>
</template>
<script>
import Helpers from '@/helpers/helpers';

export default {
  name: 'ChecklistHistoryItem',
  props: {
    checklistHistoryItem: Object
  },
  methods: {
    formatDate: Helpers.formatDate
  }
}
</script>
<style lang="scss" scoped>
  .checklist-history-item {
    background-color: rgba(37, 41, 41, 0.08);
    border-radius: 12px;
    margin-bottom: 1rem;
    padding: 1rem;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-bottom: 0;
    }
    &_user {
      margin-left: auto;
      margin-right: 1rem;
    }
  }
</style>
